var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"auth-wrapper auth-v2"},[_c('b-row',{staticClass:"auth-inner m-0"},[_c('b-link',{staticClass:"brand-logo"},[_c('vuexy-logo')],1),_c('b-col',{staticClass:"d-flex align-items-center auth-bg px-2 p-lg-5",attrs:{"offset-lg":"6","lg":"6"}},[_c('b-col',{staticClass:"px-xl-2 mx-auto",attrs:{"sm":"8","md":"6","lg":"12"}},[_c('b-card-title',{staticClass:"mb-1 font-weight-bold store-info",attrs:{"title-tag":"h2"}},[_vm._v(" "+_vm._s(_vm.$t("g.signup"))+" ")]),_c('validation-observer',{ref:"registerForm"},[_c('b-form',{staticClass:"auth-register-form mt-2",on:{"submit":function($event){$event.preventDefault();return _vm.register.apply(null, arguments)}}},[_c('b-row',[_c('b-col',{staticClass:"px-xl-2 mx-auto",attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('g.first_name'),"label-for":"first-name"}},[_c('validation-provider',{attrs:{"name":"first name","vid":"first name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"first-name","name":"first-name","state":errors.length > 0 ? false : null,"placeholder":_vm.$t('g.first_name')},model:{value:(_vm.form_data.first_name),callback:function ($$v) {_vm.$set(_vm.form_data, "first_name", $$v)},expression:"form_data.first_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"px-xl-2 mx-auto",attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('g.last_name'),"label-for":"last-name"}},[_c('validation-provider',{attrs:{"name":"Lastname","vid":"Lastname","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"last-name","name":"last-name","state":errors.length > 0 ? false : null,"placeholder":_vm.$t('g.last_name')},model:{value:(_vm.form_data.last_name),callback:function ($$v) {_vm.$set(_vm.form_data, "last_name", $$v)},expression:"form_data.last_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{staticClass:"px-xl-2 mx-auto",attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('g.email'),"label-for":"email"}},[_c('validation-provider',{attrs:{"name":"Email","vid":"email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"email","name":"email","state":errors.length > 0 ? false : null,"placeholder":_vm.$t('g.email')},model:{value:(_vm.form_data.email),callback:function ($$v) {_vm.$set(_vm.form_data, "email", $$v)},expression:"form_data.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"px-xl-2 mx-auto",attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label-for":"password","label":_vm.$t('g.password')}},[_c('validation-provider',{attrs:{"name":"Password","vid":"password","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid' : null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"password","type":_vm.passwordFieldType,"state":errors.length > 0 ? false : null,"name":"password","placeholder":"············"},model:{value:(_vm.form_data.password),callback:function ($$v) {_vm.$set(_vm.form_data, "password", $$v)},expression:"form_data.password"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIcon},on:{"click":_vm.togglePasswordVisibility}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{staticClass:"px-xl-2 mx-auto",attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label-for":"password_confirmation","label":_vm.$t('g.password_confirmation')}},[_c('validation-provider',{attrs:{"name":"Password confirmation","vid":"password confirmation","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid' : null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"password_confirmation","type":_vm.passwordConfirmationFieldType,"state":errors.length > 0 ? false : null,"name":"password confirmation","placeholder":"············"},model:{value:(_vm.form_data.password_confirmation),callback:function ($$v) {_vm.$set(_vm.form_data, "password_confirmation", $$v)},expression:"form_data.password_confirmation"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordConfirmationToggleIcon},on:{"click":_vm.togglePasswordConfirmationVisibility}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),(false)?_c('b-col',{staticClass:"px-xl-2 mx-auto",attrs:{"md":"12"}},[_c('b-form-group',{staticClass:"control-input custom-label",attrs:{"label":_vm.$t('g.country')}},[_c('validation-provider',{attrs:{"name":"Country","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"type":"text","options":_vm.countries,"state":errors.length > 0 ? false : null,"text-field":"name","value-field":"id","id":"country","name":"country","placeholder":_vm.$t('g.country'),"disabled":""},model:{value:(_vm.form_data.country_id),callback:function ($$v) {_vm.$set(_vm.form_data, "country_id", $$v)},expression:"form_data.country_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,258391451)})],1)],1):_vm._e()],1),_c('b-row',[_c('b-col',{staticClass:"px-xl-2 mx-auto",attrs:{"md":"12"}},[_c('b-form-group',{staticClass:"control-input country-code-input custom-label",attrs:{"label":_vm.$t('g.phone')}},[_c('b-input-group',{scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('vue-country-code',{ref:"vcc",staticStyle:{"max-width":"76px"},attrs:{"disabled":true,"default-country":"sa","only-countries":['sa'],"enabledCountryCode":true},on:{"onSelect":_vm.onSelect}})]},proxy:true}])},[_c('validation-provider',{attrs:{"name":"phone","rules":"required|max:11|min:9"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"number","state":errors.length > 0 ? false : null,"name":"phone","placeholder":_vm.$t('g.phone')},model:{value:(_vm.form_data.phone),callback:function ($$v) {_vm.$set(_vm.form_data, "phone", $$v)},expression:"form_data.phone"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1),_c('b-form-group',[_c('validation-provider',{attrs:{"name":"register privacy policy","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-checkbox',{attrs:{"id":"register-privacy-policy","name":"checkbox-1"},model:{value:(_vm.status),callback:function ($$v) {_vm.status=$$v},expression:"status"}},[_vm._v(" "+_vm._s(_vm.$t("g.i_agree_to"))+" "),_c('b-link',{attrs:{"href":(_vm.webSiteBaseUrl + "الشروط-والاحكام"),"target":"_blank"}},[_vm._v(_vm._s(_vm.$t("g.privacy_policy_and_terms")))])],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-button',{attrs:{"variant":"primary","block":"","type":"submit"}},[_vm._v(" "+_vm._s(_vm.$t("g.signup"))+" ")])],1)],1),_c('p',{staticClass:"mt-2 account"},[_c('span',[_vm._v(_vm._s(_vm.$t("g.already_have_an_account?")))]),_c('b-link',{attrs:{"to":{ name: 'auth-login' }}},[_c('span',{staticClass:"link"},[_vm._v(" "+_vm._s(_vm.$t("g.login")))])])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }