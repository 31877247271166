<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <b-link class="brand-logo">
        <vuexy-logo />
      </b-link>
      <b-col
        offset-lg="6"
        lg="6"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto">
          <b-card-title class="mb-1 font-weight-bold store-info" title-tag="h2">
            {{ $t("g.signup") }}
          </b-card-title>
          <!-- <b-card-text class="mb-2">
            {{ $t("g.sign_up_with") }}
          </b-card-text>
          <div class="auth-footer-btn d-flex justify-content-center">
            <b-button variant="transparent" href="javascript:void(0)">
              <b-img :src="instagram" alt="instagram-icon" />
            </b-button>
            <b-button variant="transparent" href="javascript:void(0)">
              <b-img :src="apple" alt="apple-icon" />
            </b-button>
            <b-button variant="transparent" href="javascript:void(0)">
              <b-img :src="google" alt="google-icon" />
            </b-button>
            <b-button variant="transparent" href="javascript:void(0)">
              <b-img :src="twitter" alt="twitter-icon" />
            </b-button>
          </div>

          <div class="divider my-2">
            <div class="divider-text">{{ $t("g.or") }}</div>
          </div> -->

          <validation-observer ref="registerForm">
            <b-form class="auth-register-form mt-2" @submit.prevent="register">
              <b-row>
                <b-col md="6" class="px-xl-2 mx-auto">
                  <b-form-group
                    :label="$t('g.first_name')"
                    label-for="first-name"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="first name"
                      vid="first name"
                      rules="required"
                    >
                      <b-form-input
                        id="first-name"
                        v-model="form_data.first_name"
                        name="first-name"
                        :state="errors.length > 0 ? false : null"
                        :placeholder="$t('g.first_name')"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="6" class="px-xl-2 mx-auto">
                  <b-form-group
                    :label="$t('g.last_name')"
                    label-for="last-name"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Lastname"
                      vid="Lastname"
                      rules="required"
                    >
                      <b-form-input
                        id="last-name"
                        v-model="form_data.last_name"
                        name="last-name"
                        :state="errors.length > 0 ? false : null"
                        :placeholder="$t('g.last_name')"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col md="12" class="px-xl-2 mx-auto">
                  <b-form-group :label="$t('g.email')" label-for="email">
                    <validation-provider
                      #default="{ errors }"
                      name="Email"
                      vid="email"
                      rules="required|email"
                    >
                      <b-form-input
                        id="email"
                        v-model="form_data.email"
                        name="email"
                        :state="errors.length > 0 ? false : null"
                        :placeholder="$t('g.email')"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="12" class="px-xl-2 mx-auto">
                  <b-form-group label-for="password" :label="$t('g.password')">
                    <validation-provider
                      #default="{ errors }"
                      name="Password"
                      vid="password"
                      rules="required"
                    >
                      <b-input-group
                        class="input-group-merge"
                        :class="errors.length > 0 ? 'is-invalid' : null"
                      >
                        <b-form-input
                          id="password"
                          v-model="form_data.password"
                          class="form-control-merge"
                          :type="passwordFieldType"
                          :state="errors.length > 0 ? false : null"
                          name="password"
                          placeholder="············"
                        />
                        <b-input-group-append is-text>
                          <feather-icon
                            :icon="passwordToggleIcon"
                            class="cursor-pointer"
                            @click="togglePasswordVisibility"
                          />
                        </b-input-group-append>
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col md="12" class="px-xl-2 mx-auto">
                  <b-form-group
                    label-for="password_confirmation"
                    :label="$t('g.password_confirmation')"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Password confirmation"
                      vid="password confirmation"
                      rules="required"
                    >
                      <b-input-group
                        class="input-group-merge"
                        :class="errors.length > 0 ? 'is-invalid' : null"
                      >
                        <b-form-input
                          id="password_confirmation"
                          v-model="form_data.password_confirmation"
                          class="form-control-merge"
                          :type="passwordConfirmationFieldType"
                          :state="errors.length > 0 ? false : null"
                          name="password confirmation"
                          placeholder="············"
                        />
                        <b-input-group-append is-text>
                          <feather-icon
                            :icon="passwordConfirmationToggleIcon"
                            class="cursor-pointer"
                            @click="togglePasswordConfirmationVisibility"
                          />
                        </b-input-group-append>
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="12" class="px-xl-2 mx-auto" v-if="false">
                  <b-form-group
                    class="control-input custom-label"
                    :label="$t('g.country')"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Country"
                      rules="required"
                    >
                      <b-form-select
                        type="text"
                        v-model="form_data.country_id"
                        :options="countries"
                        :state="errors.length > 0 ? false : null"
                        text-field="name"
                        value-field="id"
                        id="country"
                        name="country"
                        :placeholder="$t('g.country')"
                        disabled
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col md="12" class="px-xl-2 mx-auto">
                  <b-form-group
                    :label="$t('g.phone')"
                    class="control-input country-code-input custom-label"
                  >
                    <b-input-group>
                      <template #prepend>
                        <vue-country-code
                          style="max-width: 76px"
                          ref="vcc"
                          @onSelect="onSelect"
                          :disabled="true"
                          default-country="sa"
                          :only-countries="['sa']"
                          :enabledCountryCode="true"
                        >
                        </vue-country-code>
                      </template>
                      <validation-provider
                        #default="{ errors }"
                        name="phone"
                        rules="required|max:11|min:9"
                      >
                        <b-form-input
                          type="number"
                          v-model="form_data.phone"
                          :state="errors.length > 0 ? false : null"
                          name="phone"
                          :placeholder="$t('g.phone')"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-input-group>
                  </b-form-group>
                </b-col>
              </b-row>
              <!-- divider -->
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="register privacy policy"
                  rules="required"
                >
                  <b-form-checkbox
                    id="register-privacy-policy"
                    v-model="status"
                    name="checkbox-1"
                  >
                    {{ $t("g.i_agree_to") }}
                    <b-link
                      :href="`${webSiteBaseUrl}الشروط-والاحكام`"
                      target="_blank"
                      >{{ $t("g.privacy_policy_and_terms") }}</b-link
                    >
                  </b-form-checkbox>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-button variant="primary" block type="submit">
                {{ $t("g.signup") }}
              </b-button>
            </b-form>
          </validation-observer>

          <p class="mt-2 account">
            <span>{{ $t("g.already_have_an_account?") }}</span>
            <b-link :to="{ name: 'auth-login' }">
              <span class="link">&nbsp;{{ $t("g.login") }}</span>
            </b-link>
          </p>
        </b-col>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import VuexyLogo from "@core/layouts/components/Logo.vue";
import {
  BRow,
  BCol,
  BLink,
  BButton,
  BForm,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BImg,
  BCardTitle,
  BCardText,
  BFormSelect,
  BFormDatalist,
  BFormDatepicker,
  BFormFile,
} from "bootstrap-vue";
import { required, email, min, max } from "@validations";
import {
  togglePasswordVisibility,
  togglePasswordConfirmationVisibility,
} from "@core/mixins/ui/forms";
import store from "@/store/index";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import VueCountryCode from "vue-country-code";

export default {
  components: {
    VuexyLogo,
    BRow,
    BImg,
    BCol,
    BLink,
    BButton,
    BForm,
    BCardText,
    BCardTitle,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    ValidationProvider,
    ValidationObserver,
    BFormSelect,
    BFormDatalist,
    VueCountryCode,
    BFormDatepicker,
    BFormFile,
  },
  mixins: [togglePasswordVisibility, togglePasswordConfirmationVisibility],
  data() {
    return {
      status: "",
      sideImg: require("@/assets/images/pages/register-v2.svg"),
      instagram: require("@/assets/images/auth/instagram.svg"),
      apple: require("@/assets/images/auth/apple.svg"),
      google: require("@/assets/images/auth/google.svg"),
      twitter: require("@/assets/images/auth/twitter.svg"),
      editIcon: require("@/assets/images/icons/pencil.svg"),
      imageIcon: require("@/assets/images/icons/photo.png"),
      idCardIcon: require("@/assets/images/pages/idCard.png"),
      required,
      email,
      form_data: {
        first_name: null,
        last_name: null,
        email: null,
        password: null,
        password_confirmation: null,
        phone: null,
        country_id: 195,
        country_code: null,
      },
      countries: null,
    };
  },
  computed: {
    webSiteBaseUrl() {
      return process.env.VUE_APP_WEB_SITE_BASE_URL;
    },
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    passwordConfirmationToggleIcon() {
      return this.passwordConfirmationFieldType === "password"
        ? "EyeIcon"
        : "EyeOffIcon";
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === "dark") {
        this.sideImg = require("@/assets/images/pages/register-v2-dark.svg");
        return this.sideImg;
      }
      return this.sideImg;
    },
  },
  created() {
    this.getCountries();
  },
  methods: {
    getCountries() {
      this.$http
        .get(`web/countries`)
        .then((res) => {
          this.countries = res.data.data;
        })
        .catch((err) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$t("g.send.errorTitle"),
              text: err.response.data.message,
              icon: "AlertCircleIcon",
              variant: "danger",
            },
          });
        });
    },
    onSelect({ name, iso2, dialCode }) {
      this.form_data.country_code = dialCode;
    },
    selectCountry(countryCode, key) {
      this.$refs.vcc.activeCountry.iso2 = countryCode;
      this.$refs.vcc.activeCountry.dialCode = key;
      this.form_data.country_code = countryCode;
    },
    editImage() {
      this.form_data.id_number_image = null;
    },
    register() {
      this.$refs.registerForm.validate().then((success) => {
        if (success) {
          let formData = new FormData();
          for (const key in this.form_data) {
            if (this.form_data[key] !== null) {
              formData.append(key, this.form_data[key]);
            }
          }
          this.$http
            .post(`auth/register`, formData)
            .then((res) => {
              this.$helpers.makeToast(
                "success",
                this.$t("success"),
                this.$t("Sign_up_successfully")
              );
              this.$router.push({ name: "login" });
            })
            .catch((err) => {
              this.$helpers.handleError(err);
            });
        }
      });
    },
  },
  // watch: {
  //   "form_data.country_id": {
  //     deep: true,
  //     handler() {
  //       this.countries.map((item) => {
  //         if (item.id === this.form_data.country_id) {
  //           this.selectCountry(item.flag, item.code);
  //         }
  //       });
  //     },
  //   },
  // },
};
</script>

<style lang="scss" src="./index.scss" />
